<template>
  <div>
    <div class="table-product" :class="isMobile ? 'mobile-view' : ''">
      <a-table
        :tableLayout="isMobile ? 'fixed' : ''"
        :columns="columns"
        :rowKey="record => record.product_base_id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <!--        heading table-->
        <span slot="order_count_custom">Sản phẩm đã bán</span>
        <span slot="order_count_7d">Sản phẩm đã bán <br>7 ngày</span>
        <span slot="order_count_30d">Sản phẩm đã bán <br>30 ngày</span>
        <span slot="order_count_90d">Sản phẩm đã bán <br>90 ngày</span>
        <span slot="order_count_180d">Sản phẩm đã bán <br>180 ngày</span>

        <span slot="order_revenue_custom">Doanh số</span>
        <span slot="order_revenue_7d">Doanh số <br>7 ngày</span>
        <span slot="order_revenue_30d">Doanh số <br>30 ngày</span>
        <span slot="order_revenue_90d">Doanh số <br>90 ngày</span>
        <span slot="order_revenue_180d">Doanh số <br>180 ngày</span>

        <span slot="review_count_custom">Đánh giá</span>
        <span slot="review_revenue_7d">Đánh giá <br>7 ngày</span>
        <span slot="review_revenue_30d">Đánh giá <br>30 ngày</span>
        <span slot="review_revenue_90d">Đánh giá <br>90 ngày</span>
        <span slot="review_revenue_180d">Đánh giá <br>180 ngày</span>

        <!--        row -->
        <template slot="product_name" slot-scope="product_name, product">
          <div class="row-product-name pr-0.5" style="flex-flow: row nowrap;">
            <a-popover title="" trigger="hover">
              <template slot="content">
                <img :src="product['url_thumbnail']" alt="" class="img-responsive image-zoom" style="max-width: 200px" :class="isMobile ? 'margin-left-5' : ''">
              </template>
              <img :src="product['url_thumbnail']" alt="" class="img-responsive row-thumbnail" :class="isMobile ? 'margin-left-5' : ''">
            </a-popover>

            <div class="margin-left-5" style="width: 100%;">
              <div class="flex">
                <p class="product_name line-clamp__2" @click="onClickUrlDirect(product)">
                  <span v-if="product['official_type'] === 1" class="icon-mall-wrap"><mall-flag class="icon-mall"/></span>
                  <span>{{ product_name }}</span>
                </p>
                <a-tooltip>
                  <template slot="title" v-if="product['price_updated_at']">
                    <p>{{product_name}}</p>
                    <p class="border-t">Cập nhật thông tin sản phẩm ngày {{product['price_updated_at'] | timestampToDate}}</p>
                  </template>
                  <i>
                    <IconInformation
                      :width="isMobile ? 10 : 12"
                      :height="isMobile ? 10 : 12"
                      class="ml-0.5"
                      :style="{transform: isMobile && product['official_type'] === 1 ? 'translateY(4px)' : 'translateY(-4px)'}"
                    />
                  </i>
                </a-tooltip>
              </div>
              <div class="ant-row-flex"
                   style="flex-flow: row nowrap; justify-content: space-between; align-items: center;">
                <div>
                  <span v-if="product['order_count']" class="rating_count">Tổng sản phẩm đã bán:
                    <span>{{product['order_count'] | formatNumberHuman}}</span>
                  </span>
                </div>
              </div>
              <p>
                <span v-if="isMobile" @click="onClickUrlDirect(product)" class="link text-primary-500 cursor-pointer">Link sản phẩm</span>
              </p>
            </div>
          </div>
          <p>
            <span v-if="isDesktop" @click="onClickUrlDirect(product)" class="link text-primary-500 cursor-pointer">Link sản phẩm</span>
          </p>
        </template>

        <template slot="price_raw" slot-scope="price_raw">
          <span v-if="price_raw.min !== price_raw.max">¥{{price_raw.min | formatNumberHuman}} - ¥{{price_raw.max | formatNumberHuman}}</span>
          <span v-else>¥{{price_raw.min | formatNumberHuman}}</span>
        </template>

        <template slot="price_vnd" slot-scope="price_vnd">
          <span v-if="price_vnd.min !== price_vnd.max">{{price_vnd.min | formatCurrency}} - {{price_vnd.max | formatCurrency}}</span>
          <span v-else>{{price_vnd.min | formatCurrency}}</span>
        </template>

        <template slot="order_count_custom" slot-scope="order_count_custom">
          <span v-if="canShowStatistic">{{order_count_custom | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_7d" slot-scope="order_count_7d">
          <span v-if="canShowStatistic">{{order_count_7d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_30d" slot-scope="order_count_30d">
          <span v-if="canShowStatistic">{{order_count_30d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_90d" slot-scope="order_count_90d">
          <span v-if="canShowStatistic">{{order_count_90d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_180d" slot-scope="order_count_180d">
          <span v-if="canShowStatistic">{{order_count_180d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_custom" slot-scope="order_revenue_custom">
          <span v-if="canShowStatistic">{{order_revenue_custom | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_7d" slot-scope="order_revenue_7d">
          <span v-if="canShowStatistic">{{order_revenue_7d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_30d" slot-scope="order_revenue_30d">
          <span v-if="canShowStatistic">{{order_revenue_30d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_90d" slot-scope="order_revenue_90d">
          <span v-if="canShowStatistic">{{order_revenue_90d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_180d" slot-scope="order_revenue_180d">
          <span v-if="canShowStatistic">{{order_revenue_180d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue" slot-scope="order_revenue">
          <span v-if="canShowStatistic">{{order_revenue | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_custom" slot-scope="review_count_custom">
          <span v-if="canShowStatistic">{{review_count_custom | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_7d" slot-scope="review_count_7d">
          <span v-if="canShowStatistic">{{review_count_7d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_30d" slot-scope="review_count_30d">
          <span v-if="canShowStatistic">{{review_count_30d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_90d" slot-scope="review_count_90d">
          <span v-if="canShowStatistic">{{review_count_90d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_180d" slot-scope="review_count_180d">
          <span v-if="canShowStatistic">{{review_count_180d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="view_count" slot-scope="view_count">
          <span v-if="canShowStatistic">{{view_count | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="category_name" slot-scope="_, product">

          <a-popover trigger="hover">
            <template slot="content">
              <p v-for="category in product['categories']" :key="category['id']"
                 :class="'margin-left-'+10*category['level']">
                {{category.name}}
              </p>
            </template>
            <p style="cursor: pointer">
              {{product['category_name']}}
            </p>
          </a-popover>
        </template>


        <template slot="action" slot-scope="_, product">

          <div class="text-primary-500 cursor-pointer" :style="isMobile ? 'font-size: 8px; line-height: 18px; word-spacing: -.1ch;' : ''">
            <span type="link" @click="onClickHistoryPrice(product)">
              <a-icon type="line-chart"/>
              Lịch sử giá
            </span>
            <br>
            <span type="link" @click="onClickHistoryOrder(product)">
              <a-icon type="rise"/>
              Lịch sử bán
            </span>
            <br>
          </div>
        </template>

        <!--      <template slot="name" slot-scope="name"> {{ name.first }} {{ name.last }}</template>-->
      </a-table>

      <a-modal title=""
               :closable="true"
               :footer="null"
               v-model="showPopupProductHistory"
               :maskClosable="true"
               :width="960"
      >
        <PopupProductHistory v-if="productBaseIdCurrent" :product-base-id="productBaseIdCurrent"
                             :popup-product-history-tab="popupProductHistoryTab"
        />
      </a-modal>
    </div>
  </div>
</template>
<script>
  import Default from '@/layout/Default'
  import {
    formatCurrency,
    formatFloatNumberHuman,
    timestampToDate
  } from '@/helper/FormatHelper'
  import { MutationSearchFilter } from '@/store/modules/SearchFilterModule/mutation'
  import MallFlag from '@/components/product/MallFlag'
  import {
    canShowHistoryOrder, canShowHistoryPrice,
    canShowProductLink
  } from '@/service/user/UserPermissionService'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import PopupProductHistory from '@/views/product/PopupProductHistory'
  import {
    ActionNameConstants, CNY_RATE,
    NOTIFICATION_TYPE
  } from '@/constant/general/GeneralConstant'
  import PopupInstallExtension from '@/views/popup/PopupInstallExtension'
  import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
  import {
    generateColumnsProductGlobalTable, removeColumnsTable,
  } from "@/service/market/MarketService";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
  import IconInformation from "@/assets/icon/IconInformation";

  export default {
    components: {
      Default,
      PopupProductHistory,
      MallFlag,
      PopupInstallExtension,
      IconInformation,
    },
    props: {
      loadType: {
        default: 'pagination',
      },
      rowCount: {
        default: null
      },
      sortBy: {
        default: null
      }
    },
    data() {
      let columns = []
      return {
        pagination: false,
        isLoading: false,
        showPopupProductHistory: false,
        productBaseIdCurrent: null,
        popupProductHistoryTab: null,
        columns: columns
      }
    },
    async mounted() {
      this.iLimit = 12
      this.pagination = this.loadType === 'pagination'
        ? {
            'current': this.iPage,
            'pageSize': this.iLimit,
            'total': 240,
            'simple': true,
          }
        : false
      this.columns = generateColumnsProductGlobalTable(this.iDurationDay)
      this.columns = removeColumnsTable(this.columns, 'revenue')
      if (this.isMobile) {
        this.columns = this.columns.map(item => {
          delete item.width;
          delete item.sorter
          delete item.defaultSortOrder
          if (item.dataIndex.indexOf('order_count') === 0) {
            item.title = item.title.replace('Sản phẩm', 'Sp')
          }
          return item
        })
      }
      // let availableColumns = PERMISSION[this.permissionAvailableName]?.availableDataIndexProductGlobalTable || []
      // this.columns = generateColumnsProductGlobalTable(this.iDurationDay).filter(item => availableColumns.includes(item.scopedSlots?.customRender || item.dataIndex))
      // this.columns = this.columns.filter(item => item.dataIndex.indexOf('review') === -1 && item.dataIndex.indexOf('rating') === -1)
    },
    methods: {
      async handleTableChange(pagination, filters, sorter) {
        this.isLoading = true
        if (this.pagination) {
          const pager = { ...this.pagination }
          pager.current = pagination.current
          this.pagination = pager
        }
        if (sorter != null && Object.keys(sorter).length > 0) {
          let sort = sorter?.['field']

          if (sorter?.['field'] === 'category_name') {
            sort = 'categories.name'
          }
          if (['price_raw', 'price_vnd'].includes(sorter?.['field'])) {
            sort = 'price'
          }
          if (sorter['order'] === 'ascend') {
            sort += `__asc`
          } else if (sorter['order'] === 'descend') {
            sort += `__desc`
          } else {
            return
          }
          this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iSortBy}`, sort)
        }
        this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iPage}`, pagination.current)
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
        let options = {
          ...optionsFilter,
          ...optionsProduct
        }
        options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isDemo}`, !this.isLogin)
        await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
        this.isLoading = false
      },

      async onClickHistoryPrice(product) {
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowHistoryPrice(options)
        if (resultPermission.isPermission) {
          // window.open(product?.['url_product_detail_beecost_lsg'], "_blank")
          this.popupProductHistoryTab = 'history_price'
          this.productBaseIdCurrent = product['product_base_id']
          this.showPopupProductHistory = true
        } else {
          if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
            // hiển thị popup direct to Market View
            this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
            return
          }
          // this.$notification['warning']({
          //   message: resultPermission.title,
          //   description: resultPermission.message,
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      async onClickHistoryOrder(product) {
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowHistoryOrder(options)
        if (resultPermission.isPermission) {
          this.popupProductHistoryTab = 'history_order'
          this.productBaseIdCurrent = product['product_base_id']
          this.showPopupProductHistory = true
        } else {
          if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
            // hiển thị popup direct to Market View
            this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
            return
          }
          // this.$notification['warning']({
          //   message: resultPermission.title,
          //   description: resultPermission.message,
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      async onClickUrlDirect(product) {
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowProductLink(options)
        if (resultPermission.isPermission) {
          window.open(product?.['url_direct'], '_blank')
        } else {
          // this.$notification['warning']({
          //   message: resultPermission.title,
          //   description: resultPermission.message,
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
          // if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
          //   // hiển thị popup tới trang market
          //   this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
          // } else {
          //   this.$notification['warning']({
          //     message: resultPermission.title,
          //     description: resultPermission.message,
          //   })
          //   this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupLogin}`, true)
          // }
        }
      },
    },
    filters: {
      formatCurrency: formatCurrency,
      formatNumberHuman: formatFloatNumberHuman,
      timestampToDate: timestampToDate,
    },
    computed: {
      canShowStatistic() {
        return !this.isDefaultAccount && this.freeSearchCount !== 0
      },
      isLogin: {
        get() {
          return this.$store.state.UserModule.isLogin
        }
      },
      isExpired: {
        get() {
          return this.$store.state.UserModule.isExpired
        }
      },
      options: {
        get() {
          return this.$store.state.SearchFilterModule.options
        }
      },
      iPage: {
        get() {
          return this.$store.state.SearchFilterModule.iPage
        }
      },
      iLimit: {
        get() {
          return this.$store.state.SearchFilterModule.iLimit
        },
        set(value) {
          this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iLimit}`, value)
        }
      },
      iDurationDay: {
        get() {
          return this.$store.state.SearchFilterModule.iDurationDay
        },
      },
      products: {
        get() {
          return this.$store.state.SearchProductResultModule.products
        }
      },
      dataSource() {
        let dataSource = this.products
        if (!dataSource) {
          return null
        }
        dataSource.map(row => {
          row['price_raw'] = {
            min: Math.round(row.price_min / CNY_RATE * 100) / 100,
            max: Math.round(row.price_max / CNY_RATE * 100) / 100
          }
          row['price_vnd'] = {
            min: row.price_min,
            max: row.price_max
          }
        })
        if (this.rowCount) {
          return dataSource.slice(0, this.rowCount)
        }
        return dataSource
      }
    },
    watch: {
      sortBy(value) {
        if (value) {
          this.handleTableChange(this.pagination, null, value)
        }
      },
      iPage(value) {
        this.pagination = this.pagination
          ? {
            'current': this.iPage,
            'pageSize': this.iLimit,
            'total': 240,
            'simple': true,
          }
          : this.pagination
      },
      iLimit(value) {
        this.pagination = this.pagination
          ? {
            'current': this.iPage,
            'pageSize': this.iLimit,
            'total': 240,
            'simple': true,
          }
          : this.pagination
      }
    },
    mixins: [
      mixinStoreGetterGlobal,
      mixinStoreGetterPermission
    ]
  }
</script>

<style lang="scss" scoped>
.table-product {
  .row-thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .image-zoom {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .row-product-name {
    display: inline-flex;

    .product_name {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
      }

      &.line-clamp__2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .rating_count {
    font-size: 12px;
  }

  .ant-rate {
    font-size: 10px;
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 2px;
  }
}
.mobile-view {
  &.table-product {
    .row-thumbnail {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }

    .row-product-name {
      display: block;
      text-align: left;

      .product_name {
        font-size: 8px;
        font-weight: 500;
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }

        .icon-mall-wrap {
          height: 10px;
          width: 20px;
          display: inline-block;
        }
        .icon-mall {
          transform: scale(0.5, 0.5);
          transform-origin: bottom left;
        }
      }
    }

    .rating_count {
      font-size: 8px;
    }

    .ant-rate {
      font-size: 8px;
    }

    .link {
      font-size: 8px;
    }

    .ant-rate-star:not(:last-child) {
      margin-right: 2px;
    }
  }

  /deep/ .ant-table {

  }

  /deep/ .ant-table-tbody > tr > td {
    font-size: 8px !important;
    padding: 2px !important;
    text-align: center;
  }

  /deep/ .ant-table-thead > tr > th {
    font-size: 10px !important;
    padding: 2px 6px !important;
    font-weight: 400;
    background: unset;
  }
}
</style>
