import { render, staticRenderFns } from "./TableProductGlobal.vue?vue&type=template&id=581e3ee7&scoped=true&"
import script from "./TableProductGlobal.vue?vue&type=script&lang=js&"
export * from "./TableProductGlobal.vue?vue&type=script&lang=js&"
import style0 from "./TableProductGlobal.vue?vue&type=style&index=0&id=581e3ee7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581e3ee7",
  null
  
)

export default component.exports